// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-posts-happy-birthday-2017-index-md": () => import("./../src/posts/happy-birthday-2017/index.md" /* webpackChunkName: "component---src-posts-happy-birthday-2017-index-md" */),
  "component---src-posts-merry-christmas-2016-index-md": () => import("./../src/posts/merry-christmas-2016/index.md" /* webpackChunkName: "component---src-posts-merry-christmas-2016-index-md" */),
  "component---src-posts-to-becks-again-index-md": () => import("./../src/posts/to-becks-again/index.md" /* webpackChunkName: "component---src-posts-to-becks-again-index-md" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

