module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"extensions":[".mdx",".md"],"defaultLayouts":{"default":"/opt/build/repo/src/layouts/post.js"},"gatsbyRemarkPlugins":["gatsby-remark-images"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"love-letters","short_name":"Love Letters","start_url":"/","background_color":"#fff","theme_color":"deeppink","display":"minimal-ui","icon":"src/images/android-chrome-512x512.png"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
